<template>
  <v-container>
    <v-row>
      <v-col offset-sm="1" sm="10">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Daten laden
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              Daten wählen
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" color="grey lighten-3"> </v-card>

              <v-btn color="primary" @click="step = 2">
                Weiter
              </v-btn>

              <v-btn text>
                Abbrechen
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card
                class="mb-12"
                color="grey lighten-1"
                height="200px"
              ></v-card>

              <v-btn color="primary" @click="step = 1">
                Weiter
              </v-btn>

              <v-btn text>
                Abbrechen
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ImportWizard",
  data: () => ({
    step: 1,
  }),
  components: {},
  methods: {},
  computed: {},
  beforeCreate() {},
};
</script>
<style scoped></style>
